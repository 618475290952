import React, { useState } from 'react';
import { Box, Button, Card, Flex, Heading, Image, Text } from 'rebass';

import PrintIcn from '../../icons/print-shape.svg';

const BOX_SIZES ={
  "no_2": 2,
  "no_5": 5,
  "no_10": 10,
  "no_15": 15
}

const generateTemplateCode = (box)=>{
  const data = {
    barcode: box._id || "",
    delivery:{
      name: box.deliveryPoint.name || "",
      address: (box.deliveryPoint.contact.address.street || box.deliveryPoint.contact.address.city )? `${box.deliveryPoint.contact.address.street}, ${box.deliveryPoint.contact.address.city}` : "",
      postcode: box.deliveryPoint.contact.address.zipCode || "",
      phone:  box.deliveryPoint.contact.phone || ""
    },
    boxNumber: box.size ? BOX_SIZES[box.size] : ""
  }

  if(box.deliveryAtHome){
    data.pickup = {
      name: "Σπίτι",
      address: `${box.recipient.address.street}, ${box.recipient.address.city}`,
      postcode: box.recipient.address.zipCode || "",
      phone:  box.recipient.phone || ""
    }
  }else{
    data.pickup = {
      name: box.pickupPoint.name || "",
      address: (box.pickupPoint.contact.address.street || box.pickupPoint.contact.address.city )? `${box.pickupPoint.contact.address.street}, ${box.pickupPoint.contact.address.city}` : "",
      postcode: box.pickupPoint.contact.address.zipCode || "",
      phone:  box.pickupPoint.contact.phone || ""
    }
  }

  const LABEL_TEMPLATE = `^XA~TA000~JSN^LT0^MNW^MTD^PON^PMN^LH0,0^JMA^PR4,4~SD10^JUS^LRN^CI0^XZ
^XA
^MMT
^PW831
^LL0591
^LS0
^FO0,256^GFA,03840,03840,00012,:Z64:
eJztlk1uwyAQhUFesPQRfIXegCvlBtCbIfUilrrI1rt2YXkKGGYejV21yk8TKSPF/uSY4c0bAlHqGVcMvdwxU4rpNnwP9e7wpeKWfp4fL8CHNb9PlzJXiBdTON36wnO8WOipq9p8/FQOyjBPqmeelaWx8KKI/Do90StRkWLpSHPhAco1kcfCyQhftRMPTYNn5gGcwufwPubB/Dgv6gGdqL+tC+tFH8Af9A39RJ+DEv+94r6scQDGPv4lNPgzgIeOKPAr4kMX+bNwH3mRoTw4s1/ZAjuKX5WkMUlXjU535tCsJfYk5R7At+JPl3kV1CUPzRW45oe5UEOjrdGcatmqEWpHT+yOb+gteo69wB5h75qe6ovtGhj9KOxYz6xYpyENa+MNanwnqf3I7OiDf4R5rxOenPDIPr9RYP9Vw77nNeMegh/q7PhFSI17/P+eP3v9Y+Rzp/IMLJtMPgeF+QTNGpAn4LoNZK77RtZc8hrUb7+N5rp6rNEBd83Jz2zgfcjTC2uY18r+kx8v8EoQxm0Y6p2E0Z+gtp/v+4b5G9/wPxIK2qqrqbfxYdc3t+mz3u4L9utkHfoTaRqOMk3N341artLN6TUK4zr06vz4AoSgRqg=:D04C
^FO0,128^GFA,00768,00768,00012,:Z64:
eJzV0LENwjAQBdB/cuHSG8QrULpAYS0KJNJRshIbsIJHcAlSFBPIKf8kMA0VVz1L9v8nA7/NxtHJ032kY0/7G+3KZ9s7r7fJZCbTld53aM0zPw6Lwwgcs+ZPQNXurgOKehsgeb92ySV9t71vc2y+7bX7NMf8w31ErMtZ6mR8Xu2u85+I+jTbZToM9A70gZZC+0zrU7ha2dXw380DLHtDew==:A054
^FO96,288^GFA,02304,02304,00008,:Z64:
eJzNlEFugzAQRZ0gxTu4QblIFV+p20gR5QS9Qe/iE/QMPkHEMgsElfL/d2USCoi0YjZPGM988z2MMevjLX/MPwrbd49Z830D7nomuABmHReO6bNrkv3Kj/VG9EbDebBX3Svkwhn0r6z/App8Hrlf+aqn+tIzZT1ysD3QoF52hQ9ZW6H+J+rYIpCeRJrNJ7jQJytO1X2y7tI46JzqC/ZJ9L2QXjfIWBeT/XAgC7L8AN0XeLrw3PQl8J497l399BPsD6N1fo9twTKAfeAyGUgzeG7T/TFf9aJf0pO+Sb8zfzJn98vQD/gX50bBhnj3N+xkF9vCelLzSDKuTvMz+VEN9FI/tjpX7FyOze2Z+fe6//1/MDZ6D4q1Pi+9v/vY1hz5Nb4Bo4K9KQ==:5A9C
^FO416,320^GFA,01152,01152,00004,:Z64:
eJy9UjsOgkAUHEJBJxcg4SQGjmVhwh7EwzxvArGwFDpMCOsbMICCS4Fhkgn7eMPwPgvsjIMnA6cIaiBugcQC59DgFBiUviD35uR75qmjnt/55eiVL9BaGbiUXwOrbemT9zV66oNY3hk3Zj1cBUUhuFWC+1PwOApa9WpCQR30/U0xrZ1jc3Gq/ahh40zWtL98v7G9l1SXrbHOCpl66FJCvTsJ9ZrVY7cnxoHeCeaRmV7fzTUdPbg/a+A3elTaC9BE2kPUPxnzPfPUdfrBQ0P+x3RloNLGcwedvWys45/72Rkvg+YCXA==:4951
^FO96,0^GFA,02432,02432,00004,:Z64:
eJxjb2BgYB/Fo3gUj+JRPIpH8SgexURhagAAccE3uQ==:53F5
^FO384,0^GFA,02432,02432,00004,:Z64:
eJxjYG9gYBjFo3gUj+JRPIpH8SgexYQxlQAAFRg3Mg==:2E7B
^FO672,0^GFA,02432,02432,00004,:Z64:
eJxjYGB8wDCKR/EoHsWjeBSP4lE8ionA1AEAqDIGrQ==:9AE1
^FO32,224^GFA,00768,00768,00008,:Z64:
eJxjYKAZYP5/AEyzQ2l+GA2V50Oj2QnoQ1fPhqaPH90eKI0T/IHZBzXnfwOEbofQ/MxQexmx0zB5mHqYfph5MPPRASMTA6o+Iu2D0ezMpNmHsPgAdnokAwCjDCol:E29B
^BY3,3,65^FT790,508^BCB,,Y,N
^FD>;${data.barcode}^FS
^FT81,113^A0B,62,62^FH\^FD${data.boxNumber}^FS
^FT183,572^CI28^A0B,20,19^FH\^FD${data.delivery.name}^FS
^FT226,572^CI28^A0B,20,19^FH\^FD${data.delivery.address}^FS
^FT270,572^CI28^A0B,20,19^FH\^FD${data.delivery.postcode}^FS
^FT308,572^CI28^A0B,20,19^FH\^FD${data.delivery.phone}^FS
^FT480,578^CI28^A0B,20,19^FH\^FD${data.pickup.name}^FS
^FT523,578^CI28^A0B,20,19^FH\^FD${data.pickup.address}^FS
^FT566,578^CI28^A0B,20,19^FH\^FD${data.pickup.postcode}^FS
^FT604,578^CI28^A0B,20,19^FH\^FD${data.pickup.phone}^FS
^PQ1,0,1,Y^XZ`


    return LABEL_TEMPLATE
}

const ZebraPrint = ({boxes}) => {
  const BrowserPrint = (typeof window !== `undefined`)? window.BrowserPrint : null
  const [selectedDevice, setSelectedDevice] = useState(null)

  const runSetup = (device) =>{
    console.log(device);
    setSelectedDevice(device)
  }

  const setUpError = (error)=>{
    alert(error);
  }

  if (typeof window !== `undefined`){
    if(!selectedDevice && BrowserPrint !== undefined){
      console.log(BrowserPrint)
      BrowserPrint.getDefaultDevice("printer", runSetup, setUpError)
    }
  }

  const errorCallback = (error)=>{
    console.log('error sending to printer', error)
  }

  const printBoxes = ()=>{
    const labels = boxes.forEach(box => {
      const dataToWrite = generateTemplateCode(box);
   
      selectedDevice.send(dataToWrite, undefined, errorCallback);
    });
  }

  if(selectedDevice){
    return (<Button
    py="25px"
    px="22px"
    mr="22px"
    bg="blues.peacock"
    borderRadius="4px"
    color="white"
    onClick={printBoxes}
  >
    <Flex flexDirection="row" alignItems="center">
      <Image src={PrintIcn} pr="10px" />
      <Text
        fontSize="14px"
        fontWeight="bold"
        letterSpacing="2.34px"
      >
        ΕΚΤΥΠΩΣΗ ΤΩΝ VOUCHERS
      </Text>
    </Flex>
  </Button>)
  }

  return null;

}

export default ZebraPrint; 