import React, { useState } from 'react';
import { Box, Card, Flex, Image, Text, Heading } from 'rebass';


import { Container } from '../../../components/container';
import { MyBoxitListItem } from '../../../components/MyBoxitList';

import BackgroundImage from '../../icons/shape-box.svg';

const MyBoxitList = props => (
  <Box p="26px" css={{ height: '390px', overflowY: 'scroll' }}>
    <Flex {...props} flexDirection="column" />
  </Box>
);


export default function BoxAdminPanel({ scannedBoxes, updateConfirmedBoxIds }) {
  const [confirmedBoxes, setConfirmedBoxes] = useState(0);

  const handleBoxConfirmation = (boxId, isConfirmed) => {
    updateConfirmedBoxIds(boxId, isConfirmed);
    console.log(`BOX ${boxId} is ${isConfirmed ? '' : 'not'} confirmed!`);
    if (isConfirmed) {
      setConfirmedBoxes(confirmedBoxes + 1);
    } else {
      setConfirmedBoxes(confirmedBoxes - 1);
    }
  };

  return (
    <Container>
      <Box
        mr="16px"
        bg="white"
        width={1}
        css={{
          borderRadius: 16,
          minHeight: '500px',
        }}
      >
        <Box p="26px">
          <Flex
            flexDirection="row"
            justifyContent="space-around"
            alignItems="center"
          >
            <Heading
              fontSize="19px"
              lineHeight="1.37"
              letterSpacing="0.16px"
              color="dustyGreen"
            >
              ΕΠΙΒΕΒΑΙΩΣΗ ΔΕΜΑΤΩΝ ΑΠΟ ΔΙΑΧΕΙΡΙΣΤΗ ΤΟΥ ΣΤΑΘΜΟΥ
            </Heading>
            <Heading
              fontSize="48px"
              lineHeight="1.4"
              letterSpacing="-3.52px"
              color="dustyGreen"
            >
              {confirmedBoxes}/{scannedBoxes.length}
            </Heading>
          </Flex>
        </Box>
        <Flex flexWrap="wrap">
          {scannedBoxes && scannedBoxes.length > 0 ? (
            <MyBoxitList>
              {scannedBoxes.map((box, idx) => (
                <MyBoxitListItem
                  key={`${box._id}_${idx}`}
                  id={box._id}
                  boxData={box}
                  withConfirm
                  onBoxConfirmed={handleBoxConfirmation}
                />
              ))}
            </MyBoxitList>
          ) : (
            <Card
              width={1}
              backgroundImage={'url(' + BackgroundImage + ')'}
              backgroundSize="auto"
              backgroundRepeat="no-repeat"
              backgroundPosition="center"
              css={{
                height: '400px',
              }}
            />
          )}
        </Flex>
      </Box>
    </Container>
  );
}
