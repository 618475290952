import React, { useState } from 'react';
import { Box, Heading, Text } from 'rebass';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import scriptLoader from 'react-async-script-loader'

import { Layout } from '../../pos/layout';
import { BoxitModalProvider } from '../../pos/components/Modal';
import BoxScanningPanel from '../../pos/components/BoxScanningPanel';
import CodeConfirmPanel from '../../pos/components/CodeConfirmPanel';
import BoxAdminPanel from '../../pos/components/BoxAdminPanel/BoxAdminPanel';
import BoxCompletedPanel from '../../pos/components/BoxCompletedPanel/BoxCompletedPanel';
import BoxitModal from '../../pos/components/Modal';
import { FooterButton, HomeNavBtn } from '../../pos/components/Buttons'

const UPDATE_BOX_TRACKING_INFO = gql`
 mutation UpdateTracking( $stationId: String!, $boxIds: [ID!],  $status: String!, $validation: String! ){
  updateBoxTrackingInfo(
    stationId: $stationId
    boxIds: $boxIds
    status: $status
    validation: $validation
  ){
    status
    history{
      status
    }
  }
}`;

const SEND_EMAIL_BOXES_INFO = gql`
 mutation SendEmail($emailData: EmailTemplateInput!){
  sendEmailTemplate(data:$emailData)
}`;

const SendABoxPage = ({location}) => {
  const stationData = location.state && location.state.station ? location.state.station : {};
  const [stationState, setStationState] = useState(stationData);
  const [scannedBoxes, setScannedBoxes] = useState([]);
  const [confirmedBoxIds, setConfirmedBoxIds] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [pin, setPin] = useState(null);
  const [step, setStep] = useState(1);

  const handleBoxAdd = boxes => {
    setScannedBoxes(boxes);
  };


  const handleBackStep = () => {
    setStep(step - 1);
    setPin(null);
    setConfirmedBoxIds([]);
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };


  const openErrorModal = (msg)=>{
    setErrorMsg(msg);
    setErrorModalOpen(true);
  }

  const closeErrorModal = (msg)=>{
    setErrorMsg(null);
    setErrorModalOpen(false);
  }
  
  const onSetPin = pin => {
    if(stationState.pin === pin.toString(10)){
      setPin(pin.toString(10));
      handleNextStep();
    }else{
      console.log('error', 'pins do not match');
      openErrorModal('pins do not match')
    }
  }
  const [sendBoxesEmail] = useMutation(SEND_EMAIL_BOXES_INFO, {
    onError: error => {
      console.error(`Box creation failed! ${JSON.stringify(error)}`);
      openErrorModal('Box creation failed!')
    },
    onCompleted: async data => {
      console.log('Completed sending Email')
    }
  });

  const [updateTrackingInfo] = useMutation(UPDATE_BOX_TRACKING_INFO, {
    onError: error => {
      console.error(`Box creation failed! ${JSON.stringify(error)}`);
      openErrorModal('Box creation failed!')
    },
    onCompleted: async data => {
      handleNextStep();
      // the below was disabled since it is enabled when courier drops the boxes 
      //sendEmails();
    }
  });

  //TODO: Check the flow . This is going to send for each box an email with one box
  const sendEmails = ()=>{
    scannedBoxes.forEach((b)=>{
      if(confirmedBoxIds.includes(b._id)){
        sendBoxesEmail({
          variables: {
            emailData: {
              emailTemplate:"RECEIVER",
              email: b.recipient.email,
              templateData: {
                name:b.account.contact.fullname.split(' ')[0],
                boxes: {
                  id: b._id,
                  size:b.size,
                  name: b.deliveryAtHome ? "Στο Σπίτι" :b.pickupPoint.name,
                  address: b.deliveryAtHome ? "": `${b.pickupPoint.contact.address.street}, ${b.pickupPoint.contact.address.zipCode}, ${b.pickupPoint.contact.address.city}`
                }
              }
            }
          },
        });
      }
    })
  }

  const updateConfirmedBoxIds = (boxId, isConfirmed)=>{
    console.log(isConfirmed)
      if(isConfirmed){
        setConfirmedBoxIds([...confirmedBoxIds, boxId]);
      }else{
        setConfirmedBoxIds(confirmedBoxIds.filter(id => id !== boxId));
      }
  }

  const boxTrackingUpdate = () => {
    updateTrackingInfo({
      variables: {
        stationId: stationState._id,
        status: "scanned",
        boxIds: confirmedBoxIds,
        validation: 'deliveryPoint'
      },
    });
  }


  const handleBoxScanning = (data)=>{
    if(data.status !== "paid"){
      return {err:`Καταχώριση δεν επιτρέπεται: ${data.status}`}
    }

    if(data.deliveryPoint._id !== stationState._id){
      return {err:`Καταχώριση δεν επιτρέπεται: Wrong Station`}
    }

    const filteredExist = scannedBoxes.filter(box=> box._id === data._id)
    if(filteredExist.length !== 0){
      return {err:`Καταχώριση δεν επιτρέπεται: Added already`}
    }
    
    const boxes = scannedBoxes.concat([data]);
    handleBoxAdd(boxes);
    return true
    
  }

  const Footer = () => (
    <>
      {step === 1 && (
        <Text
          fontSize="22px"
          fontWeight="600"
          lineHeight="1.5"
          color="blues.peacock"
        >
          {scannedBoxes && scannedBoxes.length > 0
            ? `Έχετε προσθέσει ${scannedBoxes.length} δέματα προς αποστολή.`
            : 'Δεν έχετε προσθέσει δέματα προς αποστολή.'}
        </Text>
      )}

      {step === 4 && (
        <HomeNavBtn text="ΕΞΟΔΟΣ" />
      )}

      {step > 1 && step < 4 && (
        <FooterButton onClick={handleBackStep}>ΕΠΙΣΤΡΟΦΗ</FooterButton>
      )}
      <Box mx="auto" />
      { (step === 1 || step === 3) &&
        <HomeNavBtn text="ΑΚΥΡΩΣΗ" />
      }
      { ( scannedBoxes.length > 0 && step === 1 ) && (
        <FooterButton onClick={handleNextStep}>ΕΠΟΜΕΝΟ</FooterButton>
      )}

      { ( confirmedBoxIds.length > 0 &&  step === 3 ) && (
        <FooterButton onClick={boxTrackingUpdate}>ΕΠΟΜΕΝΟ</FooterButton>
      )}

      {step === 4 && (
        <Heading fontSize="40px" lineHeight="0.63" color="blues.peacock">
          weboxit.com
        </Heading>
      )}
    </>
  );

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <BoxScanningPanel
            addButtonText="ΠΡΟΣΘΗΚΗ ΔΕΜΑΤΟΣ"
            helpText="Για να προσθέσετε νέο Δέμα επιλέξτε 'ΠΡΟΣΘΗΚΗ ΔΕΜΑΤΟΣ' και
            χρησιμοποιήστε το scanner που διαθέτει ο Σταθμός."
            scannedBoxes={scannedBoxes}
            isSendBoxPage
            handleBoxScanning={handleBoxScanning}
          />
        );
      case 2:
        return (
          <CodeConfirmPanel
            title="Μπορείτε να παραδώσετε τα δέματα σας!"
            description1="Παρακαλούμε, απευθυνθείτε στον υπάλληλο του πρακτορείου AEGEAN OIL που διαχειρίζεται τον συγκεκριμένο σταθμό, για να παραλάβει τα δέματα σας προς αποστολή."
            buttonText="ΕΙΣΟΔΟΣ ΔΙΑΧΕΙΡΙΣΤΗ ΣΤΑΘΜΟΥ WEBOXIT"
            onSetPin={onSetPin}
          />
        );
      case 3:
        return (
          <BoxAdminPanel
            addButtonText="ΠΡΟΣΘΗΚΗ ΔΕΜΑΤΟΣ"
            helpText="Για να προσθέσετε νέο Δέμα επιλέξτε 'ΠΡΟΣΘΗΚΗ ΔΕΜΑΤΟΣ' και
        χρησιμοποιήστε το scanner που διαθέτει ο Σταθμός."
            scannedBoxes={scannedBoxes}
            updateConfirmedBoxIds={updateConfirmedBoxIds}
          />
        );
      case 4:
        return (
          <BoxCompletedPanel
            infoText="Μπορείτε να παραδόσετε τα Δέματα στον Διαχειρηστή του Σταθμού για να
        τα τοποθετήσει με ασφάλεια στον κλωβό αποστολής."
          />
        );
    }
  };

  return (
    <Layout footer={() => <Footer />}>
      <BoxitModalProvider>
        <BoxitModal
            isOpen={errorModalOpen}
            onBackgroundClick={() => closeErrorModal()}
            onEscapeKeydown={() => closeErrorModal()}
          >
            <Text>
              {errorMsg}
            </Text>
      </BoxitModal>
        {renderStep()}
      </BoxitModalProvider>
    </Layout>
  );
}

export default SendABoxPage;